'use strict';
import React from 'react';

class MyLeaguesCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: props.labels,
            route: props.route
        };
    }

    render() {
        const labels = this.state.labels;
        const route = this.state.route;
        const league = this.props.league;

        return (
            <React.StrictMode>
                <a className="col-12 col-lg-4 card-container" href={route + "/" + league.league.hash}>
                    <div className="row card text-center">
                        <div className="col-12">{labels.sport[league.league.sport]}</div>
                        <div className="col-12 font-semibold">{league.league.name}</div>
                        <div className="col-12 img-container">
                            <img src={league.league.image} alt={labels.image_alt}/>
                        </div>
                        <div className="col-12 uppercase">{labels.league_details}</div>
                    </div>
                </a>
            </React.StrictMode>
        );
    }
}

export default MyLeaguesCard;
