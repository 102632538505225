import React from 'react'
import ReactDOM from "react-dom";

class GameScore extends React.Component {
    constructor(props) {
        super(props);

        if (matchScoring.scoreDetail.length === 0) {
            for (let i = 0; i < matchScoring.min; i++) {
                matchScoring.scoreDetail.push(new GameSet());
            }
        } else {
            matchScoring.score = this.calculateScore(matchScoring.scoreDetail);
        }

        this.state = matchScoring;

        this.addSet = this.addSet.bind(this);
        this.updateSet = this.updateSet.bind(this);
    }

    addSet(e) {
        e.preventDefault();

        this.state.scoreDetail.push(new GameSet());

        this.forceUpdate();
    }

    updateSet(e, set, team) {
        this.state.scoreDetail[set]["team" + team] = e.target.value;

        this.setState({
            score: this.calculateScore(this.state.scoreDetail)
        });
    }

    calculateScore(scoreDetail) {
        const score = {team1: 0, team2: 0};

        scoreDetail.forEach((set) => {
            const team1 = parseInt(set.team1);
            const team2 = parseInt(set.team2);

            if (team1 > team2) {
                score.team1++;
            } else if (team2 > team1) {
                score.team2++;
            }
        });

        return score.team1 + " - " + score.team2;
    }

    render() {
        const that = this;

        return (
            <>
                <div className="form-label">{this.state.labels['update-score']}</div>

                <div className="score-container">
                    {this.state.score}
                </div>

                <div className="set-container">
                    {this.state.scoreDetail.map((set, id) => (
                        <>
                        <div className={"set-name margin-bottom-5"}>{this.state.labels['set']} {id + 1}</div>
                        <div className="form-group margin-bottom-25">
                            <input type="number" className={"form-control margin-right-10"}
                                   defaultValue={set.team1} onChange={(e) => {
                                that.updateSet(e, id, 1)
                            }}/>
                            <input type="number" className={"form-control"}
                                   defaultValue={set.team2} onChange={(e) => {
                                that.updateSet(e, id, 2)
                            }}/>
                        </div>
                        </>
                    ))}
                </div>

                <div className="add-set">
                    <button onClick={this.addSet} className="bi bi-plus-circle"></button>
                </div>


                <input type="hidden" name="result" value={this.state.score}/>
                <input type="hidden" name="score_detail" value={JSON.stringify(this.state.scoreDetail)}/>
            </>
        )
    }
}

class GameSet {
    team1 = 0;
    team2 = 0;
}

export default GameScore;

if (document.getElementById('game-score')) {
    ReactDOM.render(<GameScore/>, document.getElementById('game-score'));
}
