'use strict';
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import MyLeaguesCard from './MyLeaguesCard';
import MyLeaguesFilters from './MyLeaguesFilters';
import myLeaguesFilters from "./MyLeaguesFilters";

class MyLeagues extends React.Component {
    constructor(props) {
        super(props);


        this.state = JSON.parse(JSON.stringify(myLeagues));
        this.state.sports = this.getSport();

        this.clickedSport.bind(this);
    }


    clickedSport = (e) => {
        let leagues = [...myLeagues.leagues];
        leagues = leagues.filter(element => element.league.sport === e);

        if(this.state.active_sport === e){
            console.log("primo", this.state.active_sport,leagues, e)
            this.setState({
                active_sport: "",
                leagues: myLeagues.leagues
            })

        }else{
            this.setState({
                active_sport: e,
                leagues: leagues
            })
        }
    }

    getSport = () => {
        const sports = [];
        myLeagues.leagues.forEach((value) => {
            if (sports.indexOf(value.league.sport) === -1) {
                sports.push(value.league.sport);
            }
        });
        return sports;
    }

    componentDidMount() {
        //TODO filtri dinamici
    }


    render() {
        const labels = this.state.labels;
        const sports = this.state.sports;
        const routes = this.state.routes;


        return (
            <React.StrictMode>
                <div className="container">
                    <h2 className="text-center">{labels.title}</h2>

                    {this.state.show_filters &&
                        <MyLeaguesFilters labels={labels} sports={sports}
                                          clickedSport={this.clickedSport} active_sport={this.state.active_sport}/>
                    }

                    <div className={"league-list row " + (!this.state.show_filters ? "justify-center" : "")}>
                        {this.state.leagues.map((league) => (
                            <MyLeaguesCard labels={labels} route={routes.league} league={league}/>
                        ))}
                    </div>

                    {!this.state.show_filters &&
                        <div className="text-center">
                            <a className="btn btn-primary" href={routes.all_leagues}>{labels.see_all}</a>
                        </div>
                    }
                </div>
            </React.StrictMode>
        );
    }
}

export default MyLeagues;

if (document.getElementById('my-leagues')) {
    ReactDOM.render(<MyLeagues/>, document.getElementById('my-leagues'));
}
