'use strict';
import React from 'react';

class LeagueRankingCard extends React.Component {
    render() {
        const labels = this.props.labels;
        const ranking = this.props.ranking;
        const index = this.props.index + 1;
        const sportRoles = this.props.sportRoles;
        const sportLabels = this.props.sportLabels;
        const scoreByPoints = this.props.scoreByPoints;
        const order = this.props.order;
        const results = ranking.results ? ranking.results.split("") : [];

        const role = ranking.game_role ? sportRoles[ranking.game_role] : sportRoles[0];

        return (
            <React.StrictMode>
                <div className={"card margin-bottom-lg-30 margin-bottom-20" + (league_user_id === ranking.id ? " is-me" : "") + (typeof role !== "undefined" ? "" : " align-items-center")}>
                    <div className={"avatar-container flex flex-col align-items-center margin-right-10" +
                        (typeof role !== "undefined" ? " has-role" + (typeof role.color !== "undefined" ? " " + role.color : "") : "")}>
                        <img className={"avatar"} src={ranking.avatar} alt=""/>

                        {typeof role !== "undefined" && role.label &&
                            <strong className={"uppercase"}>{sportLabels.roles[role.label].short}</strong>
                        }
                    </div>
                    <div className="flex-grow-1">
                        <div className="flex margin-bottom-10 player-info">
                            <div className="name flex flex-col justify-content-center flex-grow-1">
                                <div className="league-name without-bar">
                                    {index}.&nbsp;
                                    {ranking.nickname || ranking.user.nickname}
                                    {(!ranking.nickname && !ranking.user.nickname) && ranking.user.name + " " + ranking.user.surname}
                                </div>
                                <div className="font-semibold">
                                    {(ranking.nickname || ranking.user.nickname) && <>{ranking.user.name} {ranking.user.surname}</>}
                                </div>
                            </div>

                            {1000 <= window.innerWidth &&
                            <div className={"last-matches flex align-items-center " + (scoreByPoints ? "justify-between" : "justify-content-around")}>
                                <div className={"text-center fw-bold text-secondary"}>
                                    <div>{labels.won}</div>
                                    <div>{ranking.won}</div>
                                </div>
                                <div className={"text-center fw-bold text-secondary"}>
                                    <div>{labels.drawn}</div>
                                    <div>{ranking.drawn}</div>
                                </div>
                                <div className={"text-center fw-bold text-secondary"}>
                                    <div>{labels.lost}</div>
                                    <div>{ranking.lost}</div>
                                </div>
                                {scoreByPoints &&
                                <>
                                    <div className={"text-center fw-bold"}>
                                        <div>{labels.positive_score}</div>
                                        <div>{ranking.positive_score}</div>
                                    </div>
                                    <div className={"text-center fw-bold"}>
                                        <div>{labels.negative_score}</div>
                                        <div>{ranking.negative_score}</div>
                                    </div>
                                </>
                                }
                            </div>
                            }

                            <div className="score flex align-items-center justify-content-end">
                                {ranking.played > 0 &&
                                <strong>
                                    {order === "time_mvp" && ranking.time_mvp}
                                    {order !== "time_mvp" && ranking.total_score}
                                </strong>}
                                {ranking.played === 0 && <i class="bi bi-dash-lg"></i>}
                            </div>
                        </div>
                        <div className="flex justify-between align-items-center">
                            {1000 > window.innerWidth &&
                                <div className={"last-matches flex align-items-center justify-between mobile-view" + (!scoreByPoints ? " short" : "")}>
                                    <div className={"text-center fw-bold text-secondary"}>
                                        <div>{labels.won}</div>
                                        <div>{ranking.won}</div>
                                    </div>
                                    <div className={"text-center fw-bold text-secondary"}>
                                        <div>{labels.drawn}</div>
                                        <div>{ranking.drawn}</div>
                                    </div>
                                    <div className={"text-center fw-bold text-secondary"}>
                                        <div>{labels.lost}</div>
                                        <div>{ranking.lost}</div>
                                    </div>
                                    {scoreByPoints &&
                                        <>
                                            <div className={"text-center fw-bold"}>
                                                <div>{labels.positive_score}</div>
                                                <div>{ranking.positive_score}</div>
                                            </div>
                                            <div className={"text-center fw-bold"}>
                                                <div>{labels.negative_score}</div>
                                                <div>{ranking.negative_score}</div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }

                            <button className="btn btn-outline-primary" data-toggle="playerStatsModal"  data-id={ranking.id}>
                                {labels.stats}
                            </button>
                        </div>
                    </div>
                </div>
            </React.StrictMode>
        );
    }
}

export default LeagueRankingCard;
