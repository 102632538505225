import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ReactDOM from "react-dom";

class Calendar extends React.Component {
    render() {
        const headerToolbar = calendarSettings.headerToolbar;

        const eventTimeFormat = {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
        };

        return (
            <FullCalendar
                plugins={[ dayGridPlugin, timeGridPlugin, listPlugin ]}
                initialView={calendarSettings.initialView}
                locale={calendarSettings.locale}
                events={calendarSettings.matches}
                headerToolbar={headerToolbar}
                displayEventEnd={true}
                eventTimeFormat={eventTimeFormat}
            />
        )
    }
}

export default Calendar;

if (document.getElementById('calendar')) {
    ReactDOM.render(<Calendar/>, document.getElementById('calendar'));
}
