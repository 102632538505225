'use strict';
import React from 'react';
import ReactDOM from 'react-dom';

import LeagueRankingCard from "./LeagueRankingCard";
import LeagueRankingFilters from "./LeagueRankingFilters";


class LeagueRanking extends React.Component {
    constructor(props) {
        super(props);

        this.state = leagueRanking;

        this.orderRanking = this.orderRanking.bind(this);
    }

    componentDidMount() {
        this.orderRanking("score");
    }

    orderRanking(event) {
        let order = typeof event === 'string' ? event : event.target.value;

        switch(order) {
            case 'score':
                order = "total_score";
                break;
        }

        this.setState({
            active_order: order,
            ranking:  JSON.parse(JSON.stringify(this.state.ranking)).sort((a,b) => {
                if(a['played'] === 0) { return 1; }
                if(b['played'] === 0) { return -1; }

                if (parseFloat(a[order]) > parseFloat(b[order])) {
                    return -1;
                }
                if (parseFloat(a[order]) < parseFloat(b[order])) {
                    return 1;
                }

                //In case of total score, we add more level of ordering:
                if(order === "total_score") {
                    //Order by wins
                    if (parseInt(a['won']) > parseInt(b['won'])) {
                        return -1;
                    }
                    if (parseInt(a['won']) < parseInt(b['won'])) {
                        return 1;
                    }

                    //Order by positive score
                    if (parseInt(a['positive_score']) > parseInt(b['positive_score'])) {
                        return -1;
                    }
                    if (parseInt(a['positive_score']) < parseInt(b['positive_score'])) {
                        return 1;
                    }
                }

                return 0;
            })
        });
    }

    render() {
        const that = this;
        const labels = this.state.labels;
        const sportLabels = this.state.sport_labels;
        const sportRoles = this.state.sport_roles;
        const scoreByPoints = this.state.score_by_points;
        const order = this.state.active_order;

        return (
            <React.StrictMode>
                <LeagueRankingFilters labels={labels} sportLabels={sportLabels} orderRanking={that.orderRanking} orders={this.state.orders}/>

                {this.state.ranking.map((ranking, index) => (
                    <LeagueRankingCard index={index} ranking={ranking} labels={labels} sportLabels={sportLabels} sportRoles={sportRoles} scoreByPoints={scoreByPoints} order={order}/>
                ))}
            </React.StrictMode>
        );
    }
}

export default LeagueRanking;

if (document.getElementById('league-ranking')) {
    ReactDOM.render(<LeagueRanking />, document.getElementById('league-ranking'));
}
